import { VscCopy } from "react-icons/vsc";
import { MdOutlineAddIcCall, MdPhonePaused } from "react-icons/md";
import { BsFillRecordBtnFill } from "react-icons/bs";
import { HiPhoneMissedCall, HiStop } from "react-icons/hi";
import { AiOutlineCopy, AiFillAudio } from "react-icons/ai";
import { GrResume } from "react-icons/gr";
import CopyToClipboard from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "react-responsive-modal/styles.css";
import { useEffect, useState, useRef, useCallback} from "react";
import ReactLoading from "react-loading";
import ShortUniqueId from "short-unique-id";
import { Modal } from "react-responsive-modal";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

import endcall from "./images/endcall.png";
import PauseIcon from "./images/pause.png";
import play from "./images/play.png";
import rec from "./images/rec.png";
import startcall from "./images/startcall.png";
import stop from "./images/stop.png";
import { MODAL_CALL_TEXT } from "./Constants";
import PageVisibility from "react-page-visibility";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaMicrophone } from "react-icons/fa6";
import { HiMiniMicrophone } from "react-icons/hi2";
import { BiMicrophone } from "react-icons/bi";
import { BiMicrophoneOff } from "react-icons/bi";
import { IoIosWarning } from "react-icons/io";
import { IoMdShare } from "react-icons/io";
import {
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton, 
  WhatsappIcon,
} from "react-share";

let msgTimer = null


{
  /* 
        ICONS - 
          call start - MdOutlineAddIcCall
          record start - AiFillAudio
          record pause - BsFillRecordBtnFill
          record resume - MdPhonePaused
          stop record - HiStop
          end call - HiPhoneMissedCall
      */
}

function App() {
  const [loader, setLoader] = useState(true);
  const [displayName, setDisplayName] = useState(undefined);
  const [isClicked, setIsClicked] = useState(false);
  const [timerValue, setTimerValue] = useState({ h: "00", m: "00", s: "00" });
  const [isPaused, setIsPaused] = useState(false);
  const [timer, setTimer] = useState(null);
  const [recordingStatusText, setRecordingStatusText] = useState("");
  const [isFileSaving, setIsFileSaving] = useState(false);
  const [isGoBackPressed, setIsGoBackPressed] = useState(false);
  const [callInProgress, setcallInProgress] = useState(false);
  const [maxDuration, setMaxDuration] = useState(-1);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const domain = "meet.haidata.ai";
  const [recOptions, setRecOptions] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [startProgressBar, setStartProgressBar] = useState(false);
  const [eventListener, setEventListener] = useState(null)
  const [recordingStarted, setRecordingStarted] = useState(false)
  const [microphoneMuted, setMicrophoneMuted] = useState(false)
  const [isHost, setIsHost] = useState(false)
  const [nameEmptyError, setNameEmptyError] = useState(false)
  const [meetingIDEmptyError, setMeetingIDEmptyError] = useState(false)
  const [localName, setLocalName] = useState('');
  const [meetingID, setMeetingID] = useState('');
  const [aidasId, setAidasID] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [userIDs, setUserIDs] = useState(null);
  const [modalMessage, setModalMessage] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [oneUserLeft, setOneUserLeft] = useState(false);
  const [oneUserLeftIdx, setOneUserLeftIdx] = useState('');
  const [didCallStart, setDidCallStart] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState('STOPPED');
  const [usersList, setUsersList] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [isConnecting, setIsConnecting] = useState(false);
  const [toastText, setToastText] = useState("");
  const [totalRecordingsSaved, setTotalRecordingsSaved] = useState(0);
  const [newUserJoined, setNewUserJoined] = useState(false);
  const [newUserDetails, setNewUserDetails] = useState([]);
  const [userLeft, setUserLeft] = useState(false);
  const [userLeftDetails, setUserLeftDetails] = useState({});
  const [roomId, setRoomId] = useState(undefined);
  const newUserDetailsRef = useRef()
  const currentProgressRef = useRef()
  const currentFileBeingSavedIdxRef = useRef()
  const [shareID, setShareID] = useState(false);
  const [isiPhone, setIsiPhone] = useState(false);
  const [currentFileBeingSavedIdx, setCurrentFileBeingSavedIdx] = useState(0);
  const progressReservedForRTC = 54

  currentProgressRef.current = currentProgress
  newUserDetailsRef.current = newUserDetails;
  currentFileBeingSavedIdxRef.current = currentFileBeingSavedIdx

  const recordTimeSlice = 1000

  const audio_data = {
    saveIndividualRecordings: 1,
    sampleRate: 44100,
    channels: 1,
    duration: -1,
    numberOfParticipants: 2,
  };

  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      if (callInProgress) {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: "cannotGoBack" })
        );
      } else {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: "canGoBack" })
        );
      }
    }
  };

  function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {

    let rtcData = window?.rtcData

    setIsHost(rtcData?.isHost)

    if(rtcData?.isHost) {
      setLocalName('Host')
    }

    setAidasID(rtcData?.localUid)
    setUserIDs(rtcData?.userIDs)

    setProjectId(rtcData?.projectId.toString())
    setDisplayName("Host");

    const isIPhone = /iPhone/i.test(navigator.userAgent);

    setIsiPhone(isIPhone)

    let listener = null

    if(isIPhone) {
      listener = window.addEventListener("message", rnMsgHandler);
    }else {
      listener = document.addEventListener("message", rnMsgHandler);
    }

    setEventListener(listener)

    return () => {
      const isIPhone = /iPhone/i.test(navigator.userAgent);

      if(isIPhone) {
        window.removeEventListener("message", rnMsgHandler); // Cleanup
      } else {
        document.removeEventListener("message", rnMsgHandler); // Cleanup
      }
    };

  }, [])


  const hostJoins = () => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: "cannotGoBack" })
    );
    setcallInProgress(true);
    let users = usersList;
    users.push({ displayName: localName, color: generateColor(), id: "local" });
    setUsersList([...users]);
    setUsersCount(users.length);
    setIsConnecting(false);
  };

  const userNameChanged = (name, id) => {
    let users = usersList;

    for (let i = 0; i < users.length; i++) {
      if (users[i].id === id) {
        users[i].displayName = name;
        break;
      }
    }

    setUsersList([...users]);
  };

  const setFileSaveProgressCb = (value) => {

   /* if(!startProgressBar) {
      setStartProgressBar(true)
    }

    setCurrentProgress(value)*/
  }

  useEffect(() => {

    if(!newUserJoined)
      return

    let newUsers = newUserDetailsRef.current;

    let users = usersList;
    for (let i = 0; i < newUsers.length; i++) {
      let name = newUsers[i].name
      let id = newUsers[i].id

      users.push({ displayName: name, color: generateColor(), id: id });
    }

    setUsersList([...users]);
    setUsersCount(users.length);

    setNewUserDetails([])
    setNewUserJoined(false)

  }, [newUserJoined])


  const userJoins =  (name, id) => {

    let userDetails = newUserDetailsRef.current

    userDetails.push({name: name, id: id})

    setNewUserDetails([...userDetails])
    setNewUserJoined(true)
  }


  const removeUserFromList = (index) => {

    let users = structuredClone(usersList);

    users.splice(index, 1)

    setUsersList([...users]);
    setUsersCount(users.length);
  }


  useEffect(() => {

    if(!userLeft)
      return

    let id = userLeftDetails.id

    let index = -1;
    let users = usersList;
    let userName = null

    for (let i = 0; i < users.length; i++) {
      if (users[i].id === id) {
        index = i;
        userName = users[i].displayName
        break;
      }
    }

    if (index === -1) {
      return
    }

    if (isRecording) {
      setModalMessage(`Participant (${userName}) dropped from the call. Recording would be stopped and saved now.`)
      setDisplayModal(true)
      setOneUserLeft(true)
      setOneUserLeftIdx(index)
    } else {
      removeUserFromList(index)
    }

    setUserLeft(false)

  }, [userLeft])


  const userLeftCb = (id) => {

    setUserLeft(true)
    setUserLeftDetails({id: id})

  };

  function refreshPage() {
    window.location.reload();
  }

  const startTimer = () => {
    let timer = setInterval(() => {
      setTimerValue((prevState) => {
        let newSeconds = parseInt(prevState.s) + 1;
        let newMinutes = parseInt(prevState.m);
        let newHours = parseInt(prevState.h);
        if (newSeconds >= 60) {
          newSeconds = 0;
          newMinutes += 1;
        }
        if (newMinutes >= 60) {
          newMinutes = 0;
          newHours += 1;
        }
        return {
          h: newHours < 10 ? "0" + newHours : newHours.toString(),
          m: newMinutes < 10 ? "0" + newMinutes : newMinutes.toString(),
          s: newSeconds < 10 ? "0" + newSeconds : newSeconds.toString(),
        };
      });
      setTimeElapsed((timeElapse) => timeElapse + 1);
    }, 1000);
    setTimer(timer);
    setRecordingStatusText("Recording");
  };

  useEffect(() => {
    if (!!timeElapsed && maxDuration !== -1) {
      if (timeElapsed >= maxDuration) {
        stopRecording();
      } else if (maxDuration - timeElapsed <= 20) {
        setRecordingStatusText(
          `Auto stopping in ${maxDuration - timeElapsed} seconds`
        );
      }
    }
  }, [timeElapsed]);

  const pause = () => {
    if (isRecording === "true") {
      endTimer();
      setRecordingStatusText("Paused");
    } else {
      startTimer();
    }
  };

  const endTimer = () => {
    setRecordingStatusText("");
    clearInterval(timer);
    setTimerValue({ h: "00", m: "00", s: "00" });
  };

  const fileSavingsCompleted = () => {
    setRecordingStatusText("");
    setIsFileSaving(false);
    setTotalRecordingsSaved(0)
    toast.success("Recording saved.", { pauseOnFocusLoss: false });
  };

  const loadScripts = async () => {

    const scriptToAdd = [
/*      
      "https://www.webrtc-experiment.com/MultiStreamsMixer.js",
      "https://www.WebRTC-Experiment.com/RecordRTC.js",
      "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js",
      "https://cdn.jsdelivr.net/npm/strophe.js/dist/strophe.js",
      "https://cdn.jsdelivr.net/npm/strophejs-plugin-disco/lib/strophe.disco.js?v=1",
      "https://code.jquery.com/jquery-3.5.1.min.js",
      "https://meet.jit.si/libs/lib-jitsi-meet.min.js",
*/
      "MultiStreamsMixer.js",
      "RecordRTC.js",
      "bootstrap.bundle.min.js",
      "strophe.js",
      "strophe.disco.js",
      "jquery-3.5.1.min.js",
      "lib-jitsi-meet.min.js",
      "example_user_join.js",
    ];

    let totalScriptsLoaded = 0;

    for (let index = 0; index < scriptToAdd.length; index++) {
      const url = scriptToAdd[index];
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.onload = function (params) { 
        totalScriptsLoaded += 1
      };
      document.body.appendChild(script);
    }

    while (totalScriptsLoaded < scriptToAdd.length) {
      await sleep(100)
    }

    setLoader(false);
  };



  useEffect(() => {

    loadScripts()

  }, []);

  useEffect(() => {

    return () => {

      if(eventListener !== null) {
        document.removeEventListener("message", eventListener);
        console.log('event unregistered...')
      }
    };

  }, []);


  const rnMsgHandler = async (event) => {
    const eventType = JSON.parse(event.data);

    if (eventType.status === "recordingSaved") {
        let currIdx = parseInt(eventType?.data?.idx)
        setTotalRecordingsSaved((prevState) => prevState + 1);
    } else if (eventType.status === "backPressed") {

      setModalMessage('Call in progress, please end the call to go back')
      setIsGoBackPressed(true);
      setDisplayModal(true)
      setShareID(false)
    } else if (eventType.status === "fileSavedPercentage") {
      let numberOfParticipants = window?.rtcData?.numberOfParticipants
      let per = parseInt(eventType?.data?.per)

      let progress = 0

      if (recOptions?.saveIndividualRecordings) {
        progress = parseInt(Math.ceil((100/numberOfParticipants)*currentFileBeingSavedIdxRef.current + per))
      } else {
        progress = per
      }

      if(progress > 100)
        progress = 100

      if (progress > progressReservedForRTC) {
        setCurrentProgress(progress)
      }
    }
  }


  useEffect(() => {

    let numberOfParticipants = window?.rtcData?.numberOfParticipants

    if ((!!!recOptions?.saveIndividualRecordings && totalRecordingsSaved === 1) || (!!recOptions?.saveIndividualRecordings && totalRecordingsSaved >= numberOfParticipants)) {
        setTimeout(() => {
          setStartProgressBar(false);
          setCurrentProgress(0);
          fileSavingsCompleted()
          setTotalRecordingsSaved(0)

          if(oneUserLeft) {
            removeUserFromList(oneUserLeftIdx)
            setOneUserLeft(false)
          }

          setIsRecording(false)

        }, 750);
    }

  }, [totalRecordingsSaved]);


  const recordRTCStopCb = async () => {

    let min = 1
    let max = 3

    if(currentProgressRef.current < progressReservedForRTC) {
      let progress = currentProgressRef.current + Math.floor((Math.random() * (max - min) + min))

      if(progress > progressReservedForRTC)
        progress = progressReservedForRTC

      setCurrentProgress((prevState) => progress)
    }

  }



  const startRecording = async () => {
    let _recOptions = null;

    setStartProgressBar(false);
    setCurrentProgress(0);
    setTotalRecordingsSaved(0)
    setCurrentFileBeingSavedIdx(0)

    if (recOptions === null) {
      if (window?.rtcData === undefined) {
        console.log("using local audio_data = ", audio_data);
        _recOptions = audio_data;
        setRecOptions(audio_data);
      } else {
        _recOptions = window?.rtcData;
        setRecOptions(window?.rtcData);
        if (window?.rtcData?.duration !== -1) {
          setMaxDuration(window?.rtcData?.duration * 60);
        }
      }
    } else {
      _recOptions = recOptions;
    }

    if (!window.readyForRecording()) {
      toast.info(`Setting up recording, please try again in a moment.`, {
        autoClose: 5000,
      });
      return;
    }

    if (usersCount > _recOptions.numberOfParticipants) {
      toast.info(
        `Participants count exceeded. Total expected participants are ${_recOptions.numberOfParticipants}`,
        { autoClose: 5000 }
      );
      return;
    } else if (usersCount < _recOptions.numberOfParticipants) {
      toast.info(
        `Total expected participants are: ${_recOptions.numberOfParticipants}`,
        { autoClose: 5000 }
      );
      return;
    }

    try {
      if (!!_recOptions.saveIndividualRecordings) {
          for(let i = 0; i < usersList.length; i++) {
            let user = usersList[i]
            await window.startIndividualRecording(user.id, _recOptions, i, recordTimeSlice);
          }
      } else {
        await window.startRecordingWithLocal(_recOptions, recordTimeSlice);
      }

      startTimer();
      setRecordingStatusText("Recording");
      setRecordingStatus("STARTED");
      setIsRecording(true);
      setRecordingStarted(true)
      setOneUserLeft(false)
    } catch (error) {
      await endCallCleanup()
      toast.info("Something went wrong. Please try again.", { pauseOnFocusLoss: false });
    }

  };

  const pauseRecording = async () => {

    if(!recordingStarted) {
      return
    }

    try {
      setRecordingStatusText("Recording Paused..");
      setIsRecording(false);
      clearInterval(timer);
      if (!!recOptions.saveIndividualRecordings) {
          for(let i = 0; i < usersList.length; i++) {
            let user = usersList[i]
            await window.pauseIndividualRecording(user.id);
          }
      } else {
        await window.pauseRecordingWithLocal();
      }
    } catch (error) {
      toast.info("Something went wrong. Please try again.", { pauseOnFocusLoss: false, autoClose: 5000 });
    }
  };

  const resumeRecording = async () => {

    if(!recordingStarted) {
      return
    }

    try {
      startTimer();
      setRecordingStatusText("Recording");
      setIsRecording(true);
      if (!!recOptions.saveIndividualRecordings) {
          for(let i = 0; i < usersList.length; i++) {
            let user = usersList[i]
            await window.resumeIndividualRecording(user.id);
          }
      } else {
        await window.resumeRecordingWithLocal();
      }

    } catch (error) {
      toast.info("Something went wrong. Please try again.", { pauseOnFocusLoss: false, autoClose: 5000 });
    }

  };

  const endCallCleanup = async () => {
    setTotalRecordingsSaved(0);
    setRecordingStatusText("");
    setIsRecording(false);
    endTimer();
    setTimeElapsed(0);
  }


  useEffect(() => {

    if(recordingStatus === 'STOPPED') {
      if(oneUserLeft) {
        removeUserFromList(oneUserLeftIdx)
        setOneUserLeft(false)
      }

      setIsRecording(false)
    }

  }, [recordingStatus]);


  const stopRecording = async () => {

    if(!recordingStarted) {
      return
    }

    await endCallCleanup();

    setStartProgressBar(true)

    console.log("recOptions in stopRecording = ", recOptions);

    let duration = parseInt(timerValue.h)*60*60 + parseInt(timerValue.m)*60 + parseInt(timerValue.s)

    try {
      setTimeout(async () => {      

        setRecordingStarted(false)
        if (!!recOptions.saveIndividualRecordings) {

            const uList = structuredClone(usersList);

            for(let index = 0; index < uList.length; index++) {
              let user = uList[index]
              await window.stopIndividualRecordingStopRTCSessions(user.id, recordRTCStopCb);
            }

            for(let index = 0; index < uList.length; index++) {
              let user = uList[index]
              await window.stopIndividualRecordingSaveWavFiles(index, duration, user.id, uList, userIDs)
              if(index > 0) {
                setCurrentFileBeingSavedIdx((prevState) => prevState + 1)
              }
            }

            setRecordingStatus("STOPPED");
        } else {
          setCurrentFileBeingSavedIdx(0)
          await window.stopRecordingWithLocal("all_recordings", setFileSaveProgressCb, duration, recordTimeSlice, recordRTCStopCb);
          setRecordingStatus("STOPPED");
        }
      }, recordTimeSlice);

    } catch (error) {
      toast.info("Something went wrong. Please try again.", { pauseOnFocusLoss: false, autoClose: 5000 });
      setCurrentProgress(0)
    }
  };

  const sendUserIdTextMsg = async () => {

    msgTimer = setInterval(() => {
        try{
          window.sendUidTextMsg()
        } catch (error) {
          console.log('ERR, sending message failed..')
        }
    }, 5000);
  }

  const startACall = async () => {

    if(!isHost) {
      let fieldsValid = true

      if(!localName.length) {
        setNameEmptyError(true)
        fieldsValid = false
      }

      if(!meetingID.length) {
        setMeetingIDEmptyError(true)
        fieldsValid = false
      }

      if(!fieldsValid) {
        return
      }
    }

    let roomID = null
    let dispName = null
    if(!isHost) {

      if(!meetingID.includes('_')) {
        toast.error("Invalid meeting ID", { pauseOnFocusLoss: false, autoClose: 5000 });
        return
      }

      if(meetingID.split('_')[0] !== projectId.toString()) {
        toast.warning("Meeting ID doesn't belong to this project.", { pauseOnFocusLoss: false, autoClose: 5000 });
        return
      }

      setDisplayName(localName);
      roomID = meetingID
      dispName = localName
    } else {
      const uid = new ShortUniqueId({ dictionary: "alpha_lower" });
      roomID = projectId.toString()+'_'+uid();
      dispName = 'Host'
    }

    setRoomId(roomID)

    let options = {
        hosts: {
          domain: domain,
          muc: `conference.${domain}`,
          focus: `focus.${domain}`,
        },
        serviceUrl: `https://${domain}/http-bind?room=${roomID}`,
      }

    window.setLocalUserID(aidasId.toString());

    setRecordingStatusText("");
    setIsConnecting(true);
    try {
      await window.startACall(
        roomID,
        dispName,
        options,
        hostJoins,
        userJoins,
        userLeftCb,
        userNameChanged
      );
      setDidCallStart(true);

      if(!isHost) {
        sendUserIdTextMsg()
      }
    } catch (error) {
      toast.info("Something went wrong. Please try again.", { pauseOnFocusLoss: false, autoClose: 5000 });
      setIsConnecting(false);
      console.log('error = ', error)
    }
  };


  const resetState = () => {
    setIsClicked(false);
    setTimerValue({ h: "00", m: "00", s: "00" });
    setIsPaused(false);
    setTimer(null);
    setRecordingStatusText("");
    setIsFileSaving(false);
    setIsGoBackPressed(false);
    setcallInProgress(false);
    setMaxDuration(-1);
    setTimeElapsed(0);
    setRecOptions(null);
    setCurrentProgress(0);
    setStartProgressBar(false);
    setEventListener(null);
    setRecordingStarted(false);
    setMicrophoneMuted(false);
    setNameEmptyError(false);
    setMeetingIDEmptyError(false);
    setMeetingID('');
    setModalMessage('');
    setDisplayModal(false);
    setOneUserLeft(false);
    setOneUserLeftIdx('');
    setDidCallStart(false);
    setIsRecording(false);
    setRecordingStatus('STOPPED');
    setUsersList([]);
    setUsersCount(0);
    setIsConnecting(false);
    setToastText('');
    setTotalRecordingsSaved(0);
    setNewUserDetails([]);
    setNewUserJoined(false);
    setUserLeftDetails({});
    setUserLeft(false);
    setShareID(false)
    setCurrentFileBeingSavedIdx(0)
  }


  const endACall = () => {

/*    try{
      window.disconnectCall()
    } catch (error) {
      toast.info("Something went wrong.", { pauseOnFocusLoss: false, autoClose: 5000 });
      console.log(error)
    }
*/
    clearInterval(msgTimer);
    window.unload();
    setcallInProgress(false);
    setUsersList([]);
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: "canGoBack" })
    );
    //refreshPage();
    resetState()
  };

  const generateColor = () => {
    let r = Math.floor(Math.random() * 156) + 100;
    let g = Math.floor(Math.random() * 156) + 100;
    let b = Math.floor(Math.random() * 156) + 100;
    return `rgb(${r}, ${g}, ${b})`; // return the generated color in rgb format
  };

  const handleMicrophoneMute = async () => {

    if(microphoneMuted) {
      setMicrophoneMuted(false)
      try {
        window?.changeMicrophoneMute(false)
      } catch (error) {
        toast.info("Something went wrong.", { pauseOnFocusLoss: false, autoClose: 2000 });
      }
    } else {
      setMicrophoneMuted(true)
      try {
        window?.changeMicrophoneMute(true)
      } catch (error) {
        toast.info("Something went wrong.", { pauseOnFocusLoss: false, autoClose: 2000 });
      }
    }

  }

  const handleCopyText = async () => {
    if (!isFileSaving) {
      toast.success("Meeting ID Copied...", { pauseOnFocusLoss: false });

      await navigator.clipboard.writeText(roomId);
    }
  };

  const handleShareID = async () => {
    if (!isFileSaving) {

      if(shareID)
        setShareID(false)
      else
        setShareID(true)

    }
  };

  const hideShareUI = async () => {
    if(shareID)
      setShareID(false)
  }

  const handleModalClose = () => {
    setDisplayModal(false)

    if(setOneUserLeft) {
      stopRecording()
    }

  };


  if(loader) {
    return (
        <div
          style={{
            width: window.innerWidth,
            height: window.innerHeight,
            backgroundColor: "#00000088",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: "1.75rem",
              fontWeight: "bold",
              margin: 0,
              color: "#fff",
            }}
          >
            Loading...
          </p>
          <ReactLoading type={"bars"} color="#fff" />
        </div>
      )
  }

  if(startProgressBar) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "1.2rem"}}>
          Saving file, please wait...
        </p>
        <ProgressBar
          now={currentProgressRef.current}
          style={{ width: 250 }}
          label={`${currentProgressRef.current}%`}
          variant="aidac" 
        />
      </div>
    );
  }

  return (
    <>
     <PageVisibility onChange={handleVisibilityChange}>
        <div onClick={hideShareUI} onClick={hideShareUI} className={`${isFileSaving ? "disable-app" : "App"}`}>
          {displayModal && (
            <Modal
              open={displayModal}
              onClose={handleModalClose}
              showCloseIcon={false}
            >
              <IoIosWarning style={{fontSize: "2rem", color: '#ffcc00'}} />
              <p className="modal-text">{modalMessage}</p>
              <div className="modal-actions">
                <button className="btn" onClick={handleModalClose}>
                  Okay
                </button>
              </div>
            </Modal>
          )}
          {!isConnecting ? (
            <>
              <div
                onClick={hideShareUI}
                id="multi-party-users-list"
                style={{
                  display: "flex",
                  alignItems: "top",
                  alignContent: "start",
                  flexWrap: "wrap",
                  padding: "20px",
                  marginLeft: "4%",
                  marginRight: "4%",
                  marginTop: "3%",
                  overflowY: "auto",
                  gap: "20px",
                  height: "350px",
                  borderRadius: "10px",
                  border: `${didCallStart ? "1px solid lightgrey" : ""} `,
                }}
              >
                {usersList.map((x) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "60%",
                        backgroundColor: x.color,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      {x.displayName[0]}
                    </div>
                    <p
                      style={{
                        margin: "0px",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {x.displayName?.length > 6
                        ? x.displayName.slice(0, 6) + ".."
                        : x.displayName}
                    </p>
                  </div>
                ))}
              </div>
              {!isFileSaving && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "absolute",
                    bottom: 185,
                  }}
                >
                  <p
                    style={{ fontSize: "1.5rem", color: "#2C3E50" }}
                    className={`${
                      recordingStatus === "PAUSED" ||
                      recordingStatusText.includes("seconds left")
                        ? "blink-text"
                        : ""
                    }`}
                  >
                    {recordingStatusText}
                    {recordingStatus === "STARTED" &&
                      !recordingStatusText.includes("seconds left") && (
                        <>
                          <span className="dot1">.</span>
                          <span className="dot2">.</span>
                          <span className="dot3">.</span>
                        </>
                      )}
                  </p>
                </div>
              )}
              {isFileSaving && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "absolute",
                    bottom: 175,
                  }}
                >
                  <p style={{ fontSize: "1.5rem", color: "#2C3E50" }}>
                    Saving file, please wait...
                  </p>
                </div>
              )}

              {!isHost && !didCallStart && (<div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: 'column',
                  position: 'absolute',
                  bottom: '42%',
                }}
              >
                  <input 
                    placeholder="Your Name" 
                    style={{marginTop: '1rem', 
                            borderRadius: '6px', 
                            fontSize: '.95rem'
                          }}
                    onChange={(evt) => {
                      setLocalName(evt.target.value);
                      setNameEmptyError(false)
                    }}
                  />
                  {nameEmptyError ? <label style={{color:'red', fontSize: '.85rem'}}>Enter your name.</label> : <></>}

                  <input 
                    placeholder="Meeting ID" 
                    style={{marginTop: '1rem', 
                            borderRadius: '6px', 
                            fontSize: '.95rem'
                          }}
                    value={meetingID}
                    onChange={(evt) => {
                      setMeetingID(evt.target.value.toLowerCase());
                      setMeetingIDEmptyError(false)
                    }}
                  />

                  {meetingIDEmptyError ? <label style={{color:'red', fontSize: '.85rem'}}>Enter meeting ID.</label> : <></>}
              </div>)}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  position: "fixed",
                  bottom: '20%',
                  left: 0,
                  right: 0,
                  flexDirection: "row",
                }}
              >
              {isHost && isRecording &&  <p
                  style={{
                    display: "flex",
                    fontSize: "1.75rem",
                    color: "#2C3E50",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ width: "40px", fontSize: '1.5rem' }}>{timerValue.h}</span>:
                  <span style={{ width: "40px", fontSize: '1.5rem' }}>{timerValue.m}</span>:
                  <span style={{ width: "40px", fontSize: '1.5rem' }}>{timerValue.s}</span>
                </p>}
              </div>
              {shareID && !isiPhone ? (
                <div 
                  style={{
                    position: "absolute",
                    top: '66%',
                    right: '22%',
                    height: '8%',
                    width: '30%',
                    borderRadius: '10px', // Controls the roundness of the corners
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '16px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional: Adds a shadow
                }}>
                  <WhatsappShareButton
                    url={roomId}
                  >
                    <WhatsappIcon size={40} round /> {/* WhatsApp icon */}
                  </WhatsappShareButton>
                  <TelegramShareButton style={{marginLeft:"0.5rem"}}
                    url={roomId}
                  >
                    <TelegramIcon size={40} round />
                  </TelegramShareButton>
                </div>
              ) : (
                <></>
              )}
              {didCallStart && !isiPhone ? (
                <div
                  onClick={handleShareID}
                  style={{
                    position: "absolute",
                    top: "68%",
                    right: 0,
                    display: "flex",
                    flexDirection: "column",
                    height: 80,
                    width: 100,
                    alignItems: "center",
                    fontSize: "0.9rem",
                    justifyContent: "center",
                  }}
                >
                  <IoMdShare color="#394256" size={"2rem"} />
                  <span style={{fontSize:".8rem"}}>Share ID</span>
                </div>
              ) : (
                <></>
              )}

              {didCallStart ? (
                <CopyToClipboard text={roomId}>
                  <div
                    style={{
                      position: "absolute",
                      top: "78%",
                      right: 0,
                      display: "flex",
                      flexDirection: "column",
                      height: 80,
                      width: 100,
                      alignItems: "center",
                      fontSize: "0.9rem",
                      justifyContent: "center",
                    }}
                  >
                    <AiOutlineCopy onClick={handleCopyText} color="#394256" size={"2.15rem"} />
                    <span style={{fontSize:".8rem"}}>Copy ID</span>
                  </div>
                </CopyToClipboard>
              ) : (
                <></>
              )}
              {didCallStart ? (
              <div
                onClick={handleMicrophoneMute}
                style={{
                  position: "absolute",
                  top: "78%",
                  left: "0%",
                  display: "flex",
                  flexDirection: "column",
                  height: 80,
                  width: 100,
                  alignItems: "center",
                  fontSize: "2.6rem",
                  justifyContent: "center",
                }}
              >
                {microphoneMuted? (<BiMicrophoneOff />) : (<BiMicrophone />)}
              </div>
              ) : (
                <></>
              )}
              <div
                className={`${
                  isFileSaving ? "disable-control" : "controls-container"
                }`}
              >
                {didCallStart ? (
                  <></>
                ) : (
                  <div>
                    <img onClick={startACall} src={startcall} width="25%" />
                  </div>
                )}
                {isHost && usersCount > 1 && !isRecording ? (
                  <div>
                    <img onClick={startRecording} src={rec} width="24%" />
                  </div>
                ) : (
                  <></>
                )}
                {/*isRecording === "STARTED" ? (
                  <div>
                    <img onClick={pauseRecording} src={PauseIcon} width="24%" />
                  </div>
                ) : (
                  <></>
                )}
                {isRecording === "PAUSED" ? (
                  <div>
                    <img onClick={resumeRecording} src={play} width="24%" />
                  </div>
                ) : (
                  <></>
                )*/}
                {isRecording ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}

                  >
                    <img onClick={stopRecording} src={stop} width="20%" />
                    <span style={{fontSize:".8rem"}}>Stop Rec.</span>
                  </div>
                ) : (
                  <></>
                )}
                {didCallStart && !isFileSaving && !isRecording ? (
                  <div>
                    <img onClick={endACall} src={endcall} width="30%" />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <div
              style={{
                width: window.innerWidth,
                height: window.innerHeight,
                backgroundColor: "#00000088",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontSize: "1.75rem",
                  fontWeight: "bold",
                  margin: 0,
                  color: "#fff",
                }}
              >
                Connecting...
              </p>
              <ReactLoading type={"bars"} color="#fff" />
            </div>
          )}
        </div>
      </PageVisibility>
      <ToastContainer transition={Zoom} autoClose={1000} />
    </>
  );
}

export default App;
